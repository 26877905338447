import React from "react"

const StaffSection = ({
  title = "Needs Title",
  color = "blue",
  children,
  slug = "",
  props,
}) => {
  return (
    <div className="my-10   mx-10" id={slug}>
      <div className="flex sticky z-10" style={{ top: "1rem" }}>
        {/* Only works with the Custom Site Colors in the Tailwind.Config.js file */}
        <h3
          className={` flag w-auto ${
            color === "blue"
              ? "bg-blue-light"
              : color === "red"
              ? "bg-red-light"
              : color === "yellow"
              ? "bg-yellow-light"
              : "bg-blue-light"
          } mb-16 mt-2 p-2 pl-4 pr-6 text-white font-heading shadow-xl`}
        >
          {title}
        </h3>
      </div>
      <div
        className="grid justify-center gap-y-20 gap-x-8 "
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 600px))",
        }}
      >
        {children}
      </div>
    </div>
  )
}
export default StaffSection
