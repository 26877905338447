import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import SectionTitle from "../components/SectionTitle"
import StaffSection from "../components/StaffSection"
import StaffCard from "../components/StaffCard"
import ContactForm from "../components/contactForm"
import Syllabics from "../components/Syllabics"
import ContactForm2 from "../components/contactForm2"
import StaffFormOverlay from "../components/staffForm/staffFormOverlay"
import ContactFormStaff from "../components/staffForm/contactFormStaff"

const ContactPage = ({ data }) => {
  const departments = data.allSanityDepartments.edges
  const staffMembers = data.allSanityStaff.edges
  const colors = ["blue", "red", "yellow"]
  const [showOverlay, setShowOverlay] = useState(false)
  const [contactId, setContactId] = useState("")
  const [contactName, setContactName] = useState("")

  const { headerImage } = data

  return (
    <Layout>
      <SEO title="Contact" />
      <Hero
        syllabics="ᑫᐃᔑᐱᓇᓇᑕᐃᐧᐡᑲᐃᐧᔑᓇᐠ"
        englishTitle="Contact Us"
        heroImage={headerImage?.childImageSharp || null}
      />
      <div className="flex justify-center">
        <div className="mx-5 mt-12 px-8 leading-8 ">
          <div className=" text-center">
            <h2 className="font-bold mb-0">Shibogama First Nations Council</h2>
            <p>
              81 King St., P.O. Box 449
              <br /> Sioux Lookout, ON P8T 1A5 <br />
              <span className={`font-bold`}>Phone:</span>{" "}
              <a className="underline " href="tel:+18077372662">
                807-737-2662
              </a>
              <br />
              <span className="font-bold">Fax:</span> 807-737-1583
            </p>
          </div>
        </div>
      </div>
      <SectionTitle className="">Reach out!</SectionTitle>

      <div className="flex justify-center">
        <ContactFormStaff
          staffId={null}
          staffName={"Shibogama First Nations Council"}
          setIsVisible={setShowOverlay}
          hideCloseButton
        />
      </div>
      <StaffFormOverlay isVisible={showOverlay} setIsVisible={setShowOverlay}>
        <ContactFormStaff
          setIsVisible={setShowOverlay}
          staffId={contactId}
          staffName={contactName}
        />
      </StaffFormOverlay>

      {/* Staff Section */}
      <div className="mb-10">
        <SectionTitle className="mt-8" id="staff">
          <Syllabics className="text-red-light">ᔑᐸᐧᑲᒪ ᐊᓄᑭᓇᑲᓇᐠ</Syllabics>
          <br /> Our Staff
        </SectionTitle>
        {/* Quick links */}
        <div className="grid justify-center">
          <div className="grid md:flex md:flex-wrap gap-x-8 gap-y-2 justify-center text-center pb-5 md:mx-20">
            {departments.map((department, index) => {
              const { departmentName, showOnStaffPage, slug } = department.node
              if (showOnStaffPage) {
                return (
                  <div key={`${index}-${slug.current}`}>
                    <a
                      href={`#${slug.current}`}
                      className=" hover:text-blue-medium font-bold uppercase  "
                    >
                      {departmentName}
                    </a>
                  </div>
                )
              }
            })}
          </div>
        </div>
        {/* Dept contact lists */}
        <div className=" grid">
          {departments.map((department, index) => {
            const {
              departmentName,
              showOnStaffPage,
              id,
              slug,
            } = department.node
            {
              /* only checks for first record -needs to look at all department name entries */
            }

            {
              /* An array of staff in the department - checks array dept field for all listed departments, for each staff (i.e. not just first entry in array) */
            }
            const departmentStaff = staffMembers.filter(staff_record => {
              {
                /* for each staff record, what departments are they in? (ARRAY) */
              }
              const inDepartments =
                staff_record?.node?.department?.map(
                  dept => dept.departmentName
                ) || null

              return inDepartments.includes(departmentName)
            })

            if (showOnStaffPage && departmentStaff.length) {
              return (
                <StaffSection
                  key={id}
                  title={departmentName}
                  color={colors[index % 3]}
                  slug={slug.current}
                >
                  {staffMembers.map(staffMember => {
                    const {
                      id,
                      jobTitle,
                      name,
                      phoneNumber,
                      image,
                      email,
                      _id,
                    } = staffMember.node
                    // Loops through all the array of Objects that all have the key "departmentName" and flattens it into a simple array with only the deparment name
                    const staffMemberDept = staffMember.node.department.map(
                      dept => dept.departmentName
                    )

                    if (staffMemberDept.includes(departmentName)) {
                      return (
                        <StaffCard
                          key={id}
                          name={name}
                          jobTitle={jobTitle}
                          phoneNumber={phoneNumber}
                          image={image}
                          showOverlay={showOverlay}
                          setShowOverlay={setShowOverlay}
                          setContactId={setContactId}
                          setContactName={setContactName}
                          email={email}
                          id={_id}
                        />
                      )
                    } else {
                      return null
                    }
                  })}
                </StaffSection>
              )
            }
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

// Sort grapql by multiple parameters https://stephencharlesweiss.com/graphql-multi-field-sorting/

export const query = graphql`
  query ContactUs {
    allSanityDepartments(sort: { fields: departmentName, order: ASC }) {
      edges {
        node {
          departmentName
          id
          showOnStaffPage
          slug {
            current
          }
        }
      }
    }
    allSanityStaff(sort: { fields: [weight, name], order: [ASC] }) {
      edges {
        node {
          ...StaffCard
          department {
            departmentName
          }
          ...HeadshotImage
        }
      }
    }
    headerImage: file(
      relativePath: { eq: "headers/D-147-07_PumphouseDawn-2-crop copy.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
